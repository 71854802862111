import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { STHeader,STFooter } from "@/layout";
import { APLightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { StudentModel } from "@/models";
import { StCommon } from "../Common";

export class StudentForm {
    acc = '';
    studentid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(10, { message: "最多輸入30字" }) name = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) phone = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(30, { message: "最多輸入30字" }) parentName = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) parentPhone = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) cid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) crid = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(50, { message: "最多輸入50字" }) address = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) pic = '';
    city = '';
    school = '';
    note = '';
    stid = '';
}

type response = { [key: string]: unknown };

@Component<ApStudentEditController>({
    components: {
        STHeader, APLightbox,STFooter
    }
})
export default class ApStudentEditController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private studentForm = new StudentForm();
    private cityList: object = {};
    private regionList: object = {};
    private stid = window.localStorage.getItem('student_stid') as string;
    private token = window.localStorage.getItem('studentToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    private errorMsgMap: { [key: string]: string } = {
        name: '',
        phone: '',
        parentName: '',
        parentPhone: '',
        cid: '',
        crid: '',
        address: '',
        pic: '',
    };

    public async created() {
        const item: { [key: string]: string } = {
            stid: this.stid,
            token: this.token,
        }
        const editData = await StudentModel.stGet(item) as response;
        this.assignDataToForm(editData);

        this.cityList = await StCommon.getAllCity(); //取得所有縣市
        const cid = this.studentForm.cid;
        this.regionList = await StCommon.getRegion(cid);//取得該縣市之鄉鎮市區
    }

    private assignDataToForm(data: response) {
        StCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as { [key: string]: string };
    
        this.studentForm.stid = item.stid;
        this.studentForm.acc = item.acc;
        this.studentForm.studentid = item.studentid;
        this.studentForm.name = item.name;
        this.studentForm.phone = item.phone;
        this.studentForm.parentName = item.parentName;
        this.studentForm.parentPhone = item.parentPhone;
        this.studentForm.cid = item.cid;
        this.studentForm.crid = item.crid;
        this.studentForm.address = item.address;
        this.studentForm.pic = item.pic;
        this.studentForm.city = item.city;
        this.studentForm.school = item.school;
        this.studentForm.note = item.note;
    }

    //取得該縣市之鄉鎮市區
    private async getRegion(cid: string) {
        this.studentForm.crid = '';
        this.regionList = await StCommon.getRegion(cid);
    }

    public changefile(event: any) {
        const file = event.target.files[0];

        if (file) { //若有上傳圖片
            /* 限制檔案上傳型別 */
            const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1);   // 得到檔案字尾名
            if (suffixName !== 'jpg' && suffixName !== 'png' && suffixName !== 'jpeg') {
                this.lightbox.errorMsg = '上傳檔案只能是 jpg、png、jpeg 格式！';
                this.lightbox.showLB = true;
                return;
            }
            /* 限制檔案上傳大小 */
            const isLimitSize = file.size / 1024 / 1024 < 3;
            if (!isLimitSize) {
                this.lightbox.errorMsg = '上傳檔案不能超過 3MB！';
                this.lightbox.showLB = true;
                return;
            }
            const reader = new FileReader();

            reader.onload = (e: any) => {
                this.studentForm.pic = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    public validateAndSubmit() {
        validate(this.studentForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        name: '',
                        phone: '',
                        parentName: '',
                        parentPhone: '',
                        cid: '',
                        crid: '',
                        address: '',
                        pic: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const formobj: HTMLFormElement = document.getElementById('studentForm') as HTMLFormElement;
                    const data = new FormData(formobj); //創建空的Formdata object

                    data.append('stid', this.studentForm.stid);
                    data.append('token', this.token);

                    const errNo = await StudentModel.stEdit(data);
                    switch (errNo) {
                        case ErrorCode.InvalidToken:
                            StCommon.logout();
                            break;
                        case ErrorCode.Success:
                            this.doubleClick = false;
                            this.$router.push("/student/student");
                            break;
                        default:
                            this.lightbox.errorMsg = ErrorMessage[errNo];
                            this.lightbox.showLB = true;
                            this.doubleClick = false;
                            break;
                    }
                }
            }
        )
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}